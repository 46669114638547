.footer {
    background-color: var(--plum);
    height: 50px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: var(--magnolia);

}

.footer .footer-container {
    width: 50%;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    margin:auto;
}

@media only screen and (max-width: 400px) {

    .footer {
        padding-bottom: 1em;
    }

    .footer .footer-container {
        text-align: center;
    }
    
}

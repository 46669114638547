.main-venue-container {
    width: 70%;
    margin: auto;
}

.venue-info-container {
    display: flex;
    padding: 1em;
    margin: auto;
    justify-content: space-around;
    margin-bottom: 2em;
    align-items: center;
}

.venue-container-text p {
    font-size: 1.25em;
    margin-left: 1em;
    width: 90%
}

.nordic-pic {
    max-height: 400px;
    min-width: 500px;
}

.map-container {
    display: flex;
    margin: auto;
    padding-bottom: 5em;
    font-size: 1.25em;
    justify-content: space-around;
    align-items: center;

}

iframe {
    margin: auto;
    height: 500px;
    width: 500px;
}

.map-container-text {
    width: 50%;
    margin: auto;
}

@media only screen and (max-width: 1200px) {

    .main-venue-container {
        margin:auto;
        display: flex;
        flex-direction: column;
        /* width: 100vw; */
    }

    .venue-info-container {
        display: flex;
        margin: auto;
        width: 80%;
        margin: auto;
        text-align: center;
    }

    .nordic-pic {
        display: none;
    }

    .map-container {
        display: inline-block;
        margin: auto;
        padding-bottom: 5em;
        width: 95%;
        font-size: 1.25em;
        text-align: center;
    }

    .map-container-text {
        width: 80%;
        margin: auto;
    }

  }

  @media only screen and (max-width: 700px) {

    iframe {
        max-height: 80%;
        max-width: 80%;
        margin: auto;
    }
}

@media only screen and (max-width: 350px) {

    iframe {
        display: none
    }
}

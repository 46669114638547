.contact-container {
    display: flex;
    font-size: 2rem;
    padding: 1em;
    width: 70%;
    margin: auto;
    justify-content: center;
    min-height: 75vh;
}

.snackbar-centered {
    position:absolute;
    top: -200px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.input-container {
    padding: .5em;
    display: flex;
    justify-content: center;
}

label {
    margin-right: .5em;
}
 
.contact-form p {
    font-size: .7em;
    text-align: center;
}

@media only screen and (max-width: 600px) {

    .contact-container {
        padding-bottom: 4em;
    }
    

    .input-container {
        min-width: 50px;
        display: flex;
        flex-direction: column;
    }

    .snackbar-centered {
    }
}
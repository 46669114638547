.App {
  height: auto;
  background-color: var(--magnolia);
  font-family: 'Libre Baskerville', serif;
}

:root {
  --magnolia: #F3EFF5;
  --plum: #420039;
  --turq: #5EC2B7;
  --seagreen: #2CA6A4;
  --elecblue: #53687E;
}



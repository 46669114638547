.header {
    background-color: var(--plum);
    align-items: center;
    text-align: center;
}

.header img {
    height: 5em;
    padding-top: 1em;
}

.header .navs {
    width: 50%;
    margin: auto;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1em;
}

.navlink {
    text-decoration: none;
    color: var(--magnolia);
    transition: all .2s ease-in-out;
}

.navlink:hover {
    color: var(--turq);
  }

@media only screen and (max-width: 1000px) {

    .header img{
        min-width: 250px;
        min-height: 25px;
    }

    .header .navs {
        width: 90%;
        font-size: .75em;
    }
}

@media only screen and (max-width: 400px) {

    .header .navs {
        width: 90%;
        font-size: .60em;
        justify-content: space-between;
    }

}

@media only screen and (max-width: 300px) {

    .header .navs {
        width: 90%;
    }

}

@media only screen and (max-width: 250px) {

    .header img{
        min-width: 150px;
        max-height: 20px;
    }

}